<template>
  <div v-if="initialLoading">
    <v-row style="margin-top: 24px">
      <v-col cols="6">
        <v-skeleton-loader type="heading"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader type="table"></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>

  <DatatableWrapper
    v-else
    :loading="devicesLoading"
    :pagination="pagination"
    title="Session Devices"
  >
    <v-data-table
      :headers="deviceHeader"
      :items="sessionDevices"
      :loading="devicesLoading"
      disable-sort
      hide-default-footer
    >
      <template #item.type="{ item }">
        <span class="cursor-pointer text-capitalize">
          {{ item?.info?.type }}
        </span>
      </template>
      <template #item.os="{ item }">
        <v-row align="center" class="py-3">
          <v-col cols="auto">
            <v-icon
              v-text="
                item?.info?.os?.name === 'iOS' ? 'mdi-apple' : 'mdi-android'
              "
            ></v-icon>
          </v-col>
          <v-col>
            <div class="regular-font font14">
              Version: {{ item?.info?.os?.version }}
            </div>
            <div class="primary--text font14">
              SDK version: {{ item?.info?.os?.sdk_version }}
            </div>
          </v-col>
        </v-row>
      </template>
      <template #item.name="{ item }">
        {{ item?.info?.name || "Anonymous" }}
      </template>
      <template #item.model="{ item }">
        {{ item?.info?.model }}
      </template>
      <template #item.push_notification="{ item }">
        <v-icon
          :color="item.push_enabled ? 'success' : 'error'"
          v-text="item.push_enabled ? 'mdi-bell' : 'mdi-bell-off'"
        ></v-icon>
      </template>
      <template #item.locale="{ item }">
        {{ item?.info?.locale }}
      </template>
      <template #item.sessions="{ item }">
        <v-btn
          class="primary--text"
          icon
          @click.stop="
            $router.push({
              name: 'app-sessions',
              query: { device_id: item.id },
            })
          "
        >
          {{ item?.stats?.sessions ?? 0 | formatNumber }}
        </v-btn>
      </template>
      <template #item.activities="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              class="primary--text"
              icon
              v-bind="attrs"
              v-on="on"
              @click.stop="
                $router.push({
                  name: 'app-sessions-activities',
                  query: { device_id: item.id },
                })
              "
            >
              {{
                [item?.stats?.screens, item?.stats.events, item?.stats?.errors]
                  | sum
                  | formatNumber
              }}
            </v-btn>
          </template>
          <ul>
            <li>Screens: {{ item?.stats?.screens ?? 0 | formatNumber }}</li>
            <li>Events: {{ item?.stats.events ?? 0 | formatNumber }}</li>
            <li>Errors: {{ item?.stats?.errors ?? 0 | formatNumber }}</li>
          </ul>
        </v-tooltip>
      </template>
      <template #item.users="{ item }">
        <v-btn
          class="primary--text"
          icon
          @click="
            $router.push({
              name: 'app-sessions-users',
              query: { device_id: item.id },
            })
          "
        >
          {{ item?.stats?.users ?? 0 | formatNumber }}
        </v-btn>
      </template>
      <template #item.applications="{ item }">
        <v-btn
          class="primary--text"
          icon
          @click="
            $router.push({
              name: 'app-sessions-applications',
              query: { device_id: item.id },
            })
          "
        >
          {{ item?.stats?.apps | formatNumber }}
        </v-btn>
      </template>
      <template #item.feedback="{ item }">
        <v-btn
          class="primary--text"
          icon
          @click.stop="
            $router.push({
              name: 'app-sessions-feedback',
              query: { device_id: item.id },
            })
          "
        >
          {{ item?.stats?.feedback | formatNumber }}
        </v-btn>
      </template>
      <template #item.errors="{ item }">
        <span
          class="primary--text cursor-pointer"
          @click="
            $router.push({
              name: 'user-session-detail',
              params: { user_id: item.id },
            })
          "
        >
          {{ item?.stats?.errors ?? 0 | formatNumber }}
        </span>
      </template>
      <template #item.created_at="{ item }">
        <vue-hoverable-date :date="item.created_at"></vue-hoverable-date>
      </template>
    </v-data-table>
  </DatatableWrapper>
</template>

<script>
import { mapGetters } from "vuex";
import { isEmpty } from "@/core/services/helper.service";
import DatatableWrapper from "@/view/components/App/DatatableWrapper";

export default {
  components: { DatatableWrapper },
  data() {
    return {
      isEmpty,

      pagination: {
        page: this.toNumber(this.$route.query.page),
        itemsPerPage: 10,
        totalItems: 0,
      },
      deviceHeader: [
        {
          text: "Type",
          value: "type",
        },
        {
          text: "OS",
          value: "os",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Model",
          value: "model",
        },
        {
          text: "Push Notification",
          value: "push_notification",
          align: "center",
        },
        {
          text: "Locale",
          value: "locale",
          align: "center",
        },
        {
          text: "Sessions",
          value: "sessions",
          align: "center",
        },
        {
          text: "Activities",
          value: "activities",
          align: "center",
        },
        {
          text: "Users",
          value: "users",
          align: "center",
        },
        {
          text: "Applications",
          value: "applications",
          align: "center",
        },
        {
          text: "Feedback",
          value: "feedback",
          align: "center",
        },
        // {
        //   text: "Error",
        //   value: "errors",
        //   align: "center",
        // },
        {
          text: "Created",
          value: "created_at",
        },
      ],
      sessionUsers: [],
      latestExpand: [],
      devicesLoading: true,
      initialLoading: true,
      sessionDevices: [],
      totalDeviceCount: 0,
    };
  },
  computed: {
    ...mapGetters({
      getAllSessionDevices: "getAllSessionDevices",
      getTotalSessionDevices: "getTotalSessionDevices",
      getTotalDevicesCount: "getTotalDevicesCount",
    }),
    lastId() {
      return this.sessionDevices[this.sessionDevices.length - 1]?.id || "";
    },
  },
  watch: {
    async "pagination.page"(newPage) {
      await this.$router.push({
        query: {
          ...this.$route.query,
          page: newPage,
        },
      });

      this.pagination.page = newPage;
      this.getSessionDevices({ last_id: this.lastId, page: newPage });
    },
    totalDeviceCount(val) {
      this.pagination.totalItems = val;
    },
  },
  created() {
    this.getSessionDevices().then(() => {
      this.initialLoading = false;
    });
  },
  methods: {
    getSessionDevices({ last_id = "", page = this.pagination.page } = {}) {
      this.devicesLoading = true;
      const params = {
        last_id,
        app_id: this.$route.params.app_id || "",
        user_id: this.$route.params.user_id || this.$route.query.user_id || "",
        page,
      };

      return this.$coreApi
        .get(
          `${this.apiRoutes.core.session.allDevices}?${this.getQuery(params)}`
        )
        .then(({ data }) => {
          this.sessionDevices = data.data;
          this.totalDeviceCount = data.meta.total || 0;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
        })
        .finally(() => {
          this.devicesLoading = false;
        });
    },
  },
};
</script>
