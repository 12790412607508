var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.initialLoading ? _c('div', [_c('v-row', {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-skeleton-loader', {
    attrs: {
      "type": "table"
    }
  })], 1)], 1)], 1) : _c('DatatableWrapper', {
    attrs: {
      "loading": _vm.devicesLoading,
      "pagination": _vm.pagination,
      "title": "Session Devices"
    }
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.deviceHeader,
      "items": _vm.sessionDevices,
      "loading": _vm.devicesLoading,
      "disable-sort": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.type",
      fn: function fn(_ref) {
        var _item$info;
        var item = _ref.item;
        return [_c('span', {
          staticClass: "cursor-pointer text-capitalize"
        }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$info = item.info) === null || _item$info === void 0 ? void 0 : _item$info.type) + " ")])];
      }
    }, {
      key: "item.os",
      fn: function fn(_ref2) {
        var _item$info2, _item$info2$os, _item$info3, _item$info3$os, _item$info4, _item$info4$os;
        var item = _ref2.item;
        return [_c('v-row', {
          staticClass: "py-3",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-icon', {
          domProps: {
            "textContent": _vm._s((item === null || item === void 0 ? void 0 : (_item$info2 = item.info) === null || _item$info2 === void 0 ? void 0 : (_item$info2$os = _item$info2.os) === null || _item$info2$os === void 0 ? void 0 : _item$info2$os.name) === 'iOS' ? 'mdi-apple' : 'mdi-android')
          }
        })], 1), _c('v-col', [_c('div', {
          staticClass: "regular-font font14"
        }, [_vm._v(" Version: " + _vm._s(item === null || item === void 0 ? void 0 : (_item$info3 = item.info) === null || _item$info3 === void 0 ? void 0 : (_item$info3$os = _item$info3.os) === null || _item$info3$os === void 0 ? void 0 : _item$info3$os.version) + " ")]), _c('div', {
          staticClass: "primary--text font14"
        }, [_vm._v(" SDK version: " + _vm._s(item === null || item === void 0 ? void 0 : (_item$info4 = item.info) === null || _item$info4 === void 0 ? void 0 : (_item$info4$os = _item$info4.os) === null || _item$info4$os === void 0 ? void 0 : _item$info4$os.sdk_version) + " ")])])], 1)];
      }
    }, {
      key: "item.name",
      fn: function fn(_ref3) {
        var _item$info5;
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : (_item$info5 = item.info) === null || _item$info5 === void 0 ? void 0 : _item$info5.name) || "Anonymous") + " ")];
      }
    }, {
      key: "item.model",
      fn: function fn(_ref4) {
        var _item$info6;
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$info6 = item.info) === null || _item$info6 === void 0 ? void 0 : _item$info6.model) + " ")];
      }
    }, {
      key: "item.push_notification",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('v-icon', {
          attrs: {
            "color": item.push_enabled ? 'success' : 'error'
          },
          domProps: {
            "textContent": _vm._s(item.push_enabled ? 'mdi-bell' : 'mdi-bell-off')
          }
        })];
      }
    }, {
      key: "item.locale",
      fn: function fn(_ref6) {
        var _item$info7;
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$info7 = item.info) === null || _item$info7 === void 0 ? void 0 : _item$info7.locale) + " ")];
      }
    }, {
      key: "item.sessions",
      fn: function fn(_ref7) {
        var _item$stats$sessions, _item$stats;
        var item = _ref7.item;
        return [_c('v-btn', {
          staticClass: "primary--text",
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.$router.push({
                name: 'app-sessions',
                query: {
                  device_id: item.id
                }
              });
            }
          }
        }, [_vm._v(" " + _vm._s(_vm._f("formatNumber")((_item$stats$sessions = item === null || item === void 0 ? void 0 : (_item$stats = item.stats) === null || _item$stats === void 0 ? void 0 : _item$stats.sessions) !== null && _item$stats$sessions !== void 0 ? _item$stats$sessions : 0)) + " ")])];
      }
    }, {
      key: "item.activities",
      fn: function fn(_ref8) {
        var _item$stats$screens, _item$stats4, _item$stats$events, _item$stats$errors, _item$stats5;
        var item = _ref8.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref9) {
              var _item$stats2, _item$stats3;
              var on = _ref9.on,
                attrs = _ref9.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "primary--text",
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.$router.push({
                      name: 'app-sessions-activities',
                      query: {
                        device_id: item.id
                      }
                    });
                  }
                }
              }, 'v-btn', attrs, false), on), [_vm._v(" " + _vm._s(_vm._f("formatNumber")(_vm._f("sum")([item === null || item === void 0 ? void 0 : (_item$stats2 = item.stats) === null || _item$stats2 === void 0 ? void 0 : _item$stats2.screens, item === null || item === void 0 ? void 0 : item.stats.events, item === null || item === void 0 ? void 0 : (_item$stats3 = item.stats) === null || _item$stats3 === void 0 ? void 0 : _item$stats3.errors]))) + " ")])];
            }
          }], null, true)
        }, [_c('ul', [_c('li', [_vm._v("Screens: " + _vm._s(_vm._f("formatNumber")((_item$stats$screens = item === null || item === void 0 ? void 0 : (_item$stats4 = item.stats) === null || _item$stats4 === void 0 ? void 0 : _item$stats4.screens) !== null && _item$stats$screens !== void 0 ? _item$stats$screens : 0)))]), _c('li', [_vm._v("Events: " + _vm._s(_vm._f("formatNumber")((_item$stats$events = item === null || item === void 0 ? void 0 : item.stats.events) !== null && _item$stats$events !== void 0 ? _item$stats$events : 0)))]), _c('li', [_vm._v("Errors: " + _vm._s(_vm._f("formatNumber")((_item$stats$errors = item === null || item === void 0 ? void 0 : (_item$stats5 = item.stats) === null || _item$stats5 === void 0 ? void 0 : _item$stats5.errors) !== null && _item$stats$errors !== void 0 ? _item$stats$errors : 0)))])])])];
      }
    }, {
      key: "item.users",
      fn: function fn(_ref10) {
        var _item$stats$users, _item$stats6;
        var item = _ref10.item;
        return [_c('v-btn', {
          staticClass: "primary--text",
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push({
                name: 'app-sessions-users',
                query: {
                  device_id: item.id
                }
              });
            }
          }
        }, [_vm._v(" " + _vm._s(_vm._f("formatNumber")((_item$stats$users = item === null || item === void 0 ? void 0 : (_item$stats6 = item.stats) === null || _item$stats6 === void 0 ? void 0 : _item$stats6.users) !== null && _item$stats$users !== void 0 ? _item$stats$users : 0)) + " ")])];
      }
    }, {
      key: "item.applications",
      fn: function fn(_ref11) {
        var _item$stats7;
        var item = _ref11.item;
        return [_c('v-btn', {
          staticClass: "primary--text",
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push({
                name: 'app-sessions-applications',
                query: {
                  device_id: item.id
                }
              });
            }
          }
        }, [_vm._v(" " + _vm._s(_vm._f("formatNumber")(item === null || item === void 0 ? void 0 : (_item$stats7 = item.stats) === null || _item$stats7 === void 0 ? void 0 : _item$stats7.apps)) + " ")])];
      }
    }, {
      key: "item.feedback",
      fn: function fn(_ref12) {
        var _item$stats8;
        var item = _ref12.item;
        return [_c('v-btn', {
          staticClass: "primary--text",
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.$router.push({
                name: 'app-sessions-feedback',
                query: {
                  device_id: item.id
                }
              });
            }
          }
        }, [_vm._v(" " + _vm._s(_vm._f("formatNumber")(item === null || item === void 0 ? void 0 : (_item$stats8 = item.stats) === null || _item$stats8 === void 0 ? void 0 : _item$stats8.feedback)) + " ")])];
      }
    }, {
      key: "item.errors",
      fn: function fn(_ref13) {
        var _item$stats$errors2, _item$stats9;
        var item = _ref13.item;
        return [_c('span', {
          staticClass: "primary--text cursor-pointer",
          on: {
            "click": function click($event) {
              return _vm.$router.push({
                name: 'user-session-detail',
                params: {
                  user_id: item.id
                }
              });
            }
          }
        }, [_vm._v(" " + _vm._s(_vm._f("formatNumber")((_item$stats$errors2 = item === null || item === void 0 ? void 0 : (_item$stats9 = item.stats) === null || _item$stats9 === void 0 ? void 0 : _item$stats9.errors) !== null && _item$stats$errors2 !== void 0 ? _item$stats$errors2 : 0)) + " ")])];
      }
    }, {
      key: "item.created_at",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_c('vue-hoverable-date', {
          attrs: {
            "date": item.created_at
          }
        })];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }